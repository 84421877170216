import { DownloadFormat } from 'constants/download';
import { asEnum } from 'utils/as-enum';
import { getDeviceOS, OS } from './detect-os';

/** Deduce the format enum from its string representation. */
export const getFormat = (
    formatString: string | undefined
): DownloadFormat | undefined => {
    if (!formatString) {
        // This is not an error (e.g. the user navigated to `/download` in which
        // case formatString would be empty).
        return undefined;
    }

    let format = asEnum(DownloadFormat, formatString);
    if (!format) {
        console.log(`Download format ${formatString} is not available`);
        return;
    }

    if (format === DownloadFormat.Desktop) {
        return getOSSpecificDesktopFormat();
    } else {
        return format;
    }
};

function getOSSpecificDesktopFormat(): DownloadFormat {
    const os = getDeviceOS();
    if (os === OS.Windows) {
        return DownloadFormat.EXE;
    } else if (os === OS.Mac) {
        return DownloadFormat.DMG;
    } else if (os === OS.Linux) {
        return DownloadFormat.AppImage;
    } else {
        return DownloadFormat.Desktop;
    }
}

export interface IArtifact {
    format: DownloadFormat;
    /** e.g. 'Ente Desktop' */
    category: string;
    /** e.g. 'macOS DMG' */
    name: string;
    /** Download URL */
    url: string;
}

/** Fetch URLs of all the latest releases for all directly downloadable formats  */
export const fetchArtifacts = async (): Promise<IArtifact[]> => {
    return (
        await Promise.all([fetchMobileArtifacts(), fetchDesktopArtifacts()])
    ).flat();
};

const fetchMobileArtifacts = async (): Promise<IArtifact[]> => {
    const makeArtifacts = (url: string) => {
        return [
            {
                url,
                format: DownloadFormat.APK,
                category: 'Ente Mobile',
                name: 'Android APK',
            },
        ];
    };

    try {
        const response = await fetch(
            'https://api.github.com/repos/ente-io/photos-app/releases/latest'
        );
        if (response.ok) {
            const jsonResponse = await response.json();
            return makeArtifacts(
                `https://github.com/ente-io/photos-app/releases/download/${jsonResponse.tag_name}/ente.apk`
            );
        }
    } catch (error) {
        console.log('Failed to fetch download URL for mobile app', error);
    }

    return makeArtifacts('https://github.com/ente-io/photos-app/releases/latest');
};

const fetchDesktopArtifacts = async (): Promise<IArtifact[]> => {
    const category = 'Ente Desktop';
    try {
        const response = await fetch(
            'https://api.github.com/repos/ente-io/photos-desktop/releases/latest'
        );
        if (response.ok) {
            const jsonResponse = await response.json();
            const { tag_name, name } = jsonResponse;
            const makeURL = (extension: string) =>
                `https://github.com/ente-io/photos-desktop/releases/download/${tag_name}/ente-${name}.${extension}`;

            return [
                {
                    category,
                    format: DownloadFormat.DMG,
                    name: 'macOS DMG',
                    url: makeURL('dmg'),
                },
                {
                    category,
                    format: DownloadFormat.EXE,
                    name: 'Windows EXE',
                    url: makeURL('exe'),
                },
                {
                    category,
                    format: DownloadFormat.AppImage,
                    name: 'Linux AppImage',
                    url: makeURL('AppImage'),
                },
                {
                    category,
                    format: DownloadFormat.RPM,
                    name: 'Linux RPM',
                    url: makeURL('rpm'),
                },
                {
                    category,
                    format: DownloadFormat.DEB,
                    name: 'Linux DEB',
                    url: makeURL('deb'),
                },
            ];
        }
    } catch (error) {
        console.log('Failed to fetch download URL for mobile app', error);
    }

    return [fallbackDesktopArtifact];
};

export const fallbackDesktopArtifact: IArtifact = {
    category: 'Ente Desktop',
    format: DownloadFormat.Desktop,
    name: 'Ente Desktop',
    url: 'https://github.com/ente-io/photos-desktop/releases/latest',
};
