/**
 * Convert a string to an enum.
 *
 * Return undefined if the string is not a member of the enum.
 *
 * Source:
 * https://stackoverflow.com/questions/17380845/how-do-i-convert-a-string-to-enum-in-typescript
 */
export const asEnum = <
    T extends { [key: string]: string },
    K extends keyof T & string
>(
    enumObject: T,
    value: string
): T[K] | undefined => {
    if (Object.values(enumObject).includes(value)) {
        return value as unknown as T[K];
    }
    return;
};
